import React, {Fragment, useEffect, useState, useRef} from "react";
import { Link, useParams } from "react-router-dom";
import { backend_api_url, cluster, getAsset, getAssetProof, getAssetsByOwner, getBalance, getClientOnRampSecret, getClientStripeSecret, getClientStripeSecretReload, getCollectionNameByAssetId, getConnectionConfig, getPaypalClientId, getPrivateKeyBase58, getPublisherNew, getSignaturesForAsset, getUsdcMintAddress, getUsdcTokenBalance, getUserBalance, getUserSessionData, giveNewGacha, isUserAdmin, saveUserReload, settingsSlider, stripe_pub_key} from "./constants";
import { AccountMeta, PublicKey , Keypair, LAMPORTS_PER_SOL,  Connection, clusterApiUrl, GetProgramAccountsFilter, sendAndConfirmTransaction, ComputeBudgetProgram } from "@solana/web3.js";
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { ToastContainer, toast } from 'react-toastify';
import { getCurrentWalletPublicKey} from './constants';

import WalletLink from "./WalletLink"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { SystemProgram, Transaction } from '@solana/web3.js';

import { SolanaWallet } from "@web3auth/solana-provider";

     
import { getOrCreateAssociatedTokenAccount  } from "@solana/spl-token";

import { Metaplex, keypairIdentity } from "@metaplex-foundation/js";
import axios from "axios";
import RecentlyMinted from "./RecentlyMinted";
import Slider from "react-slick";
import * as splToken from "@solana/spl-token";
import OnRamp from "./OnRamp";
import { createUmi } from '@metaplex-foundation/umi-bundle-defaults';
import { PROGRAM_ID as MPL_BUBBLEGUM_PROGRAM_ID } from '@metaplex-foundation/mpl-bubblegum'
//import { publicKey } from '@metaplex-foundation/umi';
//import { mplBubblegum } from '@metaplex-foundation/mpl-bubblegum';
//import { publicKeyBytes } from '@metaplex-foundation/umi'

import { createTransferInstruction } from "@metaplex-foundation/mpl-bubblegum";
 
import {
  ConcurrentMerkleTreeAccount,
  SPL_ACCOUNT_COMPRESSION_PROGRAM_ID,
  SPL_NOOP_PROGRAM_ID,
} from "@solana/spl-account-compression";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import base58 from "bs58";
import CheckoutFormOld from "./CheckoutFormOld";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import PaginatedCollectionDesigns from "./PaginatedCollectionDesigns";

//const { Token, TOKEN_PROGRAM_ID, Account, Transaction, SystemProgram } = require('@solana/spl-token');

const CollectionWallet = (props : any) => {
 
    const amountInput = useRef<HTMLInputElement>(null);
    const addressInput = useRef<HTMLInputElement>(null);
 
    const [show, setShow] = useState(false);

    const [showNftSend, setShowNftSend] = useState(false);
    const handleShowNftSend = () => setShowNftSend(true);

    const handleShow = () => setShow(true);

    //const [solParams, setSolParams] = useState(null);

    const { connection } = useConnection();


    const [allNfts, setAllNfts] = useState<any[]>(null)  
    const [filteredNfts, setFilteredNfts] = useState<any[]>(null)  

    const [showPreview, setShowPreview] = useState(false);
    const handleClosePreview = () => setShowPreview(false);
    const handleShowPreview = () => setShowPreview(true);

    const [showPreviewSend, setShowPreviewSend] = useState(false);
    const handleClosePreviewSend = () => setShowPreviewSend(false);
    const handleShowPreviewSend = () => setShowPreviewSend(true);
  
 

    
    const tokenTypeInput = useRef<any>(null);
    const solAmountInput = useRef<any>(null);
    const toAddressInput = useRef<any>(null);

    const toAddressNftInput = useRef<any>(null);

    const [collectionName, setCollectionName] = useState("temp");
    const [usernameJsx, setUsernameJsx] = useState<JSX.Element>(null);

    const [previewImageName, setPreviewImageName] = useState<JSX.Element>(null);
    const [previewImageUrl, setPreviewImageUrl] = useState("");
    const [previewImageDesc, setPreviewImageDesc] = useState("");
    const [previewCollectionName, setPreviewCollectionName] = useState<JSX.Element>(null);
    const [selectedNftAddress, setSelectedNftAddress] = useState("");
    const [selectedNftType, setSelectedNftType] = useState("");


    const [selectedNftMintAddress, setSelectedNftMintAddress] = useState("");


    const [num, setNum] = useState(0)
    const [propertiesJsx, setPropertiesJsx] = useState<JSX.Element[] | null>(null)

    const [sendTokenTitle, setSendTokenTitle] = useState("");
    const [selectedTokenType, setSelectedTokenType] = useState("");
    const [destinationWallet, setDestinationWallet] = useState("");
    const [numOfToken, setNumOfToken] = useState("");

    const [sendNftTitle, setSendNftTitle] = useState("");
    const [sendTokenErrorMessage, setSendTokenErrorMessage] = useState("");
    const [sendNftErrorMessage, setSendNftErrorMessage] = useState("");

    

    const [renderedElements, setRenderedElements] = useState([]);
     
    const [currentIndex, setCurrentIndex] = useState(0);

    const searchInput = useRef<HTMLInputElement>(null);

 
 

    const [showError, setShowError] = useState(false);
    const handleCloseError = () => setShowError(false);
    const handleShowError = () => setShowError(true);

    const [showSuccess, setShowSuccess] = useState(false);
    const handleCloseSuccess = () => setShowSuccess(false);
    const handleShowSuccess = () => setShowSuccess(true);
    
    
    const [mintError, setMintError] = useState("");
    const [mintErrorHeader, setMintErrorHeader] = useState("");

    const [hideInitial, setHideInitial] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isRefresh, setIsRefresh] = useState(false);

    const config = getConnectionConfig(); 
 
    let paramsMain = useParams();

 
    
    const setMintErrorMessage = (message, header) => {
        if(message) {
            setMintError(message);
            setMintErrorHeader(header)
            handleClose();
            handleShowError();
        }
    }

    const viewNftsOwned = async (address) => {
        

        
        const userData = getUserSessionData();
        
        let params = {
            token: userData ? userData.token : '',
            secret: userData ? userData.secret : '',
            candy_machine_address: address
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/machine/all-nfts', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    
                    loadAllNft(data.nfts);
                    setCollectionName(data.gacha.name)
                    
                }
            });

    }

     
    const loadAllNft = async (allNFTs) => {
        setIsProcessing(true);
        
        const newArr1 = [];
        for(var i in allNFTs) {
            if(allNFTs[i].status === 'LOADED') {
                newArr1.push(allNFTs[i])
            }
        }
        setAllNfts(newArr1);
        
        loadAllNftImages(newArr1);
    }
 
 
   
    const showSendNftConfirm = async () => {
         const userData = getUserSessionData();

         //props.loginWeb3Auth();

        if(getUserSessionData() == null || props.provider == null) {
            handleClosePreview();
            props.loginWeb3Auth();
            return;
        }
        
        if(!props.provider){
            handleClosePreview();
            props.loginWeb3Auth();
        }


        if(userData && isUserAdmin()) {
            
            if(userData.wallet) {
                    
                    try{
                        const toWallet = (toAddressNftInput.current!.value);

                        await connection.getAccountInfo(new PublicKey(toWallet));
                        // handleShowNftSend();
                        setDestinationWallet(toWallet);
                        setSendNftTitle("CONFIRM SEND");

    
                    }
                    catch (e) {
                    // code for error handeling
                                console.log(e)
                        setSendNftErrorMessage("Invalid Wallet Address.");
                        return false;
                    }
                

            }
        }
    }
    
    const handleClose = () => {
       // console.log("handleClose");
        setShow(false);

        handleClosePreviewSend();

    };

    const handleCloseNftSend = () => {
        //console.log("handleCloseNftSend");
        setShowNftSend(false);

        handleClosePreview();

    };

    const backSendNft = () => {
        if(sendNftTitle === 'CONFIRM SEND') {
            setSendNftErrorMessage("");
            setSendNftTitle("SEND NFT");
        }else{
            handleClosePreview();
        }
    }
 

    const loadAllNftImages = async (allNfts) => {
        //console.log(uris); 
        setIsProcessing(false);
           
        loadImageNfts(allNfts, false, true);

    }

    const loadImageNfts = (filteredNft, isRefresh, isTimeout) => {
 
        setFilteredNfts(filteredNft);
        setHideInitial(true); 
 
        setIsProcessing(false);

    }
 
 
    const showNftData = (address) => {
        
            setSendNftTitle("SEND TOKENS");
            
            for(var i in allNfts) {
                if(allNfts[i].assetId === address) {

                    setPropertiesJsx([]);
                    setPreviewImageDesc("");
                    setPreviewImageUrl(allNfts[i].imageUri);

                    const requestOptions = {
                        method: 'GET'
                    };
                    fetch(allNfts[i].metadataUri, requestOptions)
                        .then(response => response.json())
                        .then(data => {
                            if(data.attributes) {
                                    //console.log('show att');
                                    const attributes = Array.isArray(data.attributes) ? data.attributes : JSON.parse(data.attributes);  
                                    //console.log(attributes);
                                    const properties = chunkArray(attributes, 2);
                                     
                                    const chunkedJSX = [];
                
                                    for (let i = 0; i < properties.length; i ++) {
                                        let elem = properties[i];
                
                                        if(elem.length === 2) {
                                            chunkedJSX.push(
                                                <div className='row-elem' key={i}>
                                                    {elem[0] &&  <div className="custom-column">{elem[0].trait_type} : <span className="prop-value">{elem[0].value}</span></div>} 
                                                    {elem[1] &&  <div className="custom-column">{elem[1].trait_type} : <span className="prop-value">{elem[1].value}</span></div>} 
                                                </div>
                                            );
                                        }else{
                                            chunkedJSX.push(
                                                <div className='row-elem' key={i}>
                                                    {elem[0] &&  <div className="custom-column">{elem[0].trait_type} : <span className="prop-value">{elem[0].value}</span></div>} 
                                                </div>
                                            );
                                        }
                                    }
                                    setPropertiesJsx (chunkedJSX);                        
                            }
                            if(data.description) {
                                setPreviewImageDesc(data.description);
                            }
                        });

                    //console.log(nfts[i]);
                    setSelectedNftAddress(address);
                    //setSelectedNftType(allNfts[i].type);

                    //setPreviewCollectionName(nfts[i].collection ? nfts[i].collection : '');
                    let nftName = allNfts[i].name.split(".")[0];

                    //showCollectionLink(allNfts[i].collectionAddress, (allNfts[i].collection ? allNfts[i].collection : ''), address, nftName, allNfts[i].type);
 

                    if(false && allNfts[i].type === 'nft') {
                        setPreviewImageName(<a target="_blank"  rel="noreferrer" href={'https://explorer.solana.com/address/'+address+'?cluster='+ cluster}>{nftName}</a>);
                    } else {
                        setPreviewImageName(<a target="_blank"  rel="noreferrer" href={'https://translator.shyft.to/address/'+address+'/?cluster='+cluster+'&compressed=true'}>{nftName}</a>);
                    }
                    break;
                }
            }
            setSendNftErrorMessage("");
            handleShowPreview(); 
            //props.loginWeb3Auth();
 
    }

    const chunkArray = (arr, chunkSize) => {
        const chunkedArray = [];
        
        for (let i = 0; i < arr.length; i += chunkSize) {
            chunkedArray.push(arr.slice(i, i + chunkSize));
        }
        
        return chunkedArray;
        }
      
    const sendNftNow = async () => {
        //console.log("sendNftNow "  + selectedNftAddress);

        if(!props.provider){
            //handleCloseNftSend();
             props.loginWeb3Auth();
        }

        let balance = 0;
        const userData = getUserSessionData();
  
        if(userData) {
            
            if(userData.wallet) {

                const pubkey =  new PublicKey(userData.wallet);
                //balance = await getBalance(pubkey, connection);
                
            }
        }

          
            
                await transferCompressedNFT();

                 
    }
    
    const transferCompressedNFT = async () => {

        try{ 
            
            const params1 = {
                candyMachineAddress: paramsMain.address,
                receiver: destinationWallet,
                assetId: selectedNftAddress
            }; 
            handleShow();

            await giveNewGacha(params1, setMintErrorMessage, handleClose);
             
             
            afterTransferProcessing();
                

        }
        catch (e) {
            handleCloseSuccess();
            console.log(e)
            setSendNftErrorMessage("Invalid Address.");
            return false;
        }
      };
       

      const reloadAllNft = () => {
        viewNftsOwned(paramsMain.address);
      }
    const afterTransferProcessing = () => {
 
        setIsProcessing(true);
        setIsRefresh(true);
        setTimeout(reloadAllNft, 2000)

        setShow(false);
        //handleCloseNftSend();
        handleClosePreview();
        //handleShowSuccess();
        //loadAllNft();
        
        
    }

    const backSendBalance = () => {
        setSendTokenErrorMessage("");

        setSendTokenTitle("SEND TOKENS");
    }
    const closeSendToken = () => {
        if(sendTokenTitle === 'SEND TOKENS') {
            handleClosePreviewSend();
        }else if(sendTokenTitle === 'CONFIRM SEND') {
            setSendTokenErrorMessage("");
            backSendBalance();
        }
    }
    const showSendBalance = () => {
        //console.log(props.provider);

        if(props.provider) {
            setSendTokenErrorMessage("");

            setSendTokenTitle('SEND TOKENS');
            handleShowPreviewSend();
        }else{
            props.loginWeb3Auth();
        }
    }
 

    const renderAsset = (asset, index) => {
   
        return <Fragment key={index + (new Date()).getTime()} >
            
                <a style={{"textAlign": 'center'}} href={'#nftInfo-'+asset.assetId} onClick={() => showNftData(asset.assetId)}  className={"imgSliderDetails-1 nftDetail1 "}> <img alt="" src={asset.imageUri} /> <br /> {asset.name}</a>

        </Fragment>
           
       
      }

    useEffect(() => { 
        const userData1 = getUserSessionData();
        if(userData1 && isUserAdmin()) { 
            props.loginWeb3Auth(); 
            viewNftsOwned(paramsMain.address); 
            
        }else{
            window.location.href = "/";
        }
 
    }, []);
 
    const submitHandler = (e) => {
        e.preventDefault();
        //console.log(searchInput.current.value);

        if(searchInput.current.value) {
            let searchTerm = (searchInput.current.value);

            if(searchTerm == '') {
                setFilteredNfts(allNfts);

            }else if(allNfts.length > 0) {
                let filteredNfts1 = [];
                for(var i in allNfts) {
                    if(allNfts[i].name.search(searchTerm) != -1) {
                        filteredNfts1.push(allNfts[i]);
                    }
                }

                setFilteredNfts(filteredNfts1);
            }
        } else {
            setFilteredNfts(allNfts);

        }
    }

    props.setClassInfo('walletPg');

    settingsSlider.infinite = num >= 5 ? true : false;
    

    return (
        <Fragment>

        <div className="contents">

            <h5 className="outer">{collectionName}</h5>
            

            <div className="collections recentlyMinted nftsMinted">
            <h5>NFTS MINTED - ({filteredNfts && filteredNfts.length ? filteredNfts.length : '-'})</h5> 
            
            <div className="searchBoxWallet">

                <form name="frmSearch" id="frmSearch" method="get" onSubmit={submitHandler} >

                <input type="image" src="/images/icons/search.png" id="BtnSearch" title=" Search " alt="" />	
                <input type="text" name="Keywords" id="Keywords" className="textbox" placeholder="search" ref={searchInput} />

                </form>

            </div> 

            <div className="collectionsDiv">
                {
                    isProcessing && <p style={{"textAlign": "left"}}>{isRefresh ? 'Refreshing' : 'Processing'}...</p>
                }
                
{
filteredNfts && filteredNfts.length > 0 && <PaginatedCollectionDesigns 
    isProcessing={isProcessing} 
    renderAsset={renderAsset}
    items ={filteredNfts}
    >

    </PaginatedCollectionDesigns>
}
            </div>


            </div>

            </div>
  

<Modal className="modal-preview" show={showPreview} onHide={backSendNft}
  size="sm"
  aria-labelledby="contained-modal-title-vcenter"
  centered>
        <Modal.Header closeButton>
        <Modal.Title className="custom-modal-title">{sendNftTitle == 'CONFIRM SEND' ? sendNftTitle : previewImageName}</Modal.Title>
        </Modal.Header>
        <Modal.Body> 
        <div style={{"color" : 'red', 'textAlign': 'center', "display" : sendNftErrorMessage !== '' ? 'block' : 'none' }}>{sendNftErrorMessage}</div>

                <div id='NftDetail' style={ {"padding" : "10px", "background" : '#ffffff', "margin" : "0px", "display" : sendNftTitle === 'CONFIRM SEND' ? 'none' : 'block'} }>
                    <img alt="" src={previewImageUrl}/>
                    <br/><br/>
                    {/* <p>OWNED BY : <span>{usernameJsx}</span></p>
                    { previewCollectionName && <Fragment><p className="pull-left">COLLECTION : {previewCollectionName}</p></Fragment>} */}

                    <div className="scrolling-info">
                    <p className="pull-left">DESCRIPTION : <span className="full-text" dangerouslySetInnerHTML={{ __html: previewImageDesc }} /></p>
                     

                    <p className="pull-left">PROPERTIES<br/></p>
                        <div className="custom-row">
                            {
                                propertiesJsx ? propertiesJsx : 'N/A'
                            }
                        </div>
                    </div>
                    {/* <br/> */}
                    <div className="sendNftName">
                        <p>To : Wallet</p>&nbsp;
                        <input type="text" name="ToWallet" id="ToWallet" className="textbox" ref={toAddressNftInput}/>
                        &nbsp;<button onClick={showSendNftConfirm}  className="buttonLink btn-generic">SEND NOW</button>
                    </div>
 
                </div>

                <div id='SendToken' style={ {"padding" : '10px', "display" : sendNftTitle === 'CONFIRM SEND' ? 'block' : 'none'}}>
                    <div className="form-group" style={{"marginBottom" : "35px", "wordBreak": "break-word"}}>
                            Are you sure you want to send this NFT to this {destinationWallet}? 
                    </div> 
                    <button onClick={backSendNft} className="buttonLink btn-generic">&lt;&lt; BACK</button>

                    <button onClick={sendNftNow} className="buttonLink btn-generic">SEND</button>
                </div>
        </Modal.Body>
        <Modal.Footer> 
        </Modal.Footer>
    </Modal>


    <Modal className="modal-processing" show={showSuccess} onHide={handleCloseSuccess} backdrop="static">
           <Modal.Header closeButton={true}>
                <Modal.Title>Notification</Modal.Title>
            </Modal.Header>
            
            <Modal.Body>
              <div className="success-create">Send Successful</div>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal> 

    <Modal  className="modal-preview"  show={showError} onHide={handleCloseError} backdrop="static">
                <Modal.Header closeButton={true}>
                <Modal.Title>{mintErrorHeader}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
    
                    <div style={{"textAlign" : "center", "marginBottom" : "30px"}}>
                        {mintError}
                    </div>
    
                    <div className="sendNftName" style={{"textAlign" : "center"}}>
                        <button onClick={handleCloseError} className="buttonLink btn-generic">Close</button>
                    </div>
                    
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal> 
             
 


 
                    

                <Modal className="modal-processing" show={show} onHide={handleClose} backdrop="static">
                <Modal.Header closeButton={false}>
                <Modal.Title>Processing...</Modal.Title>
                </Modal.Header>
                <Modal.Body>
    
                <div className="progressBar"><div></div></div>
    
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal> 


        </Fragment>

    );
}

export default CollectionWallet;