import React, {Fragment, useState, useEffect, useRef} from "react";

import MainContainer from "./MainContainer"; 
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import {
  BrowserRouter ,
  Routes, //replaces "Switch" used till v5
  Route,
} from "react-router-dom";


import { backend_api_url, checkPendingData, getBalance, getConnectionConfig, getPublicWallet, getUserSessionData } from "./constants";
import { PublicKey, LAMPORTS_PER_SOL, RpcResponseAndContext, SignatureResult} from "@solana/web3.js";
import { useConnection } from '@solana/wallet-adapter-react';


import { ToastContainer, toast } from 'react-toastify';

import { Web3Auth  } from "@web3auth/modal";
import { CHAIN_NAMESPACES, SafeEventEmitterProvider, WALLET_ADAPTERS } from "@web3auth/base";
import RPC from "./SolanaRpc";
import RightSidebar from "./sidebar/RightSidebar";
import Header from "./sidebar/Header";
import ListingForm from "./ListingForm";
import MyGacha from "./MyGacha";
import ViewGacha from "./ViewGacha";
import WalletInfo from "./WalletInfo";
import MyAccount from "./MyAccount";
import CreateCollection from "./CreateCollection";
import WalletActivity from "./WalletActivity";
import UserView from "./UserView";
import SearchView from "./SearchView";
import SampleModal from "./SampleModal";
import TestStripe from "./TestStripe";
import WalletActivityOld from "./WalletActivityOld";
import Support from "./footer/Support";
import Privacy from "./footer/Privacy";
import Terms from "./footer/Terms";
import WalletActivityNew from "./WalletActivityNew";
import AdminContainer from "./AdminContainer";
import UserContainer from "./UserContainer";
import CreatorContainer from "./CreatorContainer";
import MachineListContainer from "./MachineListContainer";
import PurchaseListContainer from "./PurchaseListContainer";
import ActionsContainer from "./ActionsContainer";
import AdjustCreditsContainer from "./AdjustCreditsContainer";
import HowItWorks from "./footer/HowItWorks";
import ForCreators from "./footer/ForCreators";
import TestPaypal from "./TestPaypal";
import CollectionWallet from "./CollectionWallet";
import Purchases from "./Purchases";

const Gacha = (props: any) => {

    const [unreadCount, setUnreadCount] = useState<number>(0);
    const [solBalance, setSolBalance] = useState<number>(0);
    const { connection } = useConnection();
    const [web3auth, setWeb3auth] = useState<Web3Auth | null>(null);
    const [provider, setProvider] = useState<SafeEventEmitterProvider | null>(null);
    const [hasUserInfo, setHasUserInfo] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    const [avatar, setAvatar] = useState<string>("/images/icons/account-mobile.png");

    
    const usernameInput = useRef<HTMLInputElement>(null);
    const emailInput = useRef<HTMLInputElement>(null);

    let claimCallback;

    const [currentUsername, setCurrentUsername] = useState<string>("");
    const [currentEmail, setCurrentEmail] = useState<string>("");
    const [promptMail, setPromptMail] = useState(false);

    const loadWalletBalance = async (pubKey : string) => {
        //console.log(pubKey);
        // console.log('loadSolBalance');
        const pubkey =  new PublicKey(pubKey);

        const balance = await getBalance(pubkey, connection);
        //console.log(balance);
        setSolBalance(balance);
    }
    const loadSolBalance = async () => {

        const userData = getUserSessionData();
        if(userData) {
            
            if(userData.wallet) {
                await loadWalletBalance(userData.wallet);
            }else{
                const params = {
                    token : userData ? userData.token : '',
                    secret : userData ? userData.secret : '',
                }
                const requestOptions = {
                    method: 'POST',
                    body: JSON.stringify(params)
                };
                fetch(backend_api_url + 'api/v1/users/wallet', requestOptions)
                    .then(response => response.json())
                    .then(data => {
                        if(data.status === 1) {
                            loadWalletBalance(data.primary_wallet);
                        }
                    });
            }
        }
    }   
    const fetchUnreadCount = () => {
        // const userData = getUserSessionData();
        // if(!userData) {
        //     return false;
        // }

        // const params = {
        //     token : userData ? userData.token : '',
        //     secret : userData ? userData.secret : '',
        // }
        // //console.log(params);


        // const requestOptions = {
        //     method: 'POST',
        //     body: JSON.stringify(params)
        // };
        // fetch(backend_api_url + 'api/v1/messages/unread-count', requestOptions)
        //     .then(response => response.json())
        //     .then(data => {
        //         if(data.status === 1) {
        //             setUnreadCount(data.unreadCount);
        //         }
                 
        //     });
    }  

    const loadMessageUnread = () => {
        //fetchUnreadCount();
        //console.log('loadMessageUnread');
    }
    
    useEffect(() => {

        //console.log('fetch unread count');
        // showToastError("Hello 123");
        // handleShowCompleteAccount();
        
        //console.log(getConnectionConfig());

        const userData = getUserSessionData();  
        
        //console.log(userData);

        if(userData) {
            //fetchUnreadCount();
            if(userData.isNew === 1) {
                setCurrentUsername(userData.username);

                setCurrentEmail('');
                handleShowCompleteAccount();
            }

            if(userData.isPromptEmail === 1) {
                setPromptMail(true);
                setCurrentEmail('');
                handleShowCompleteAccount();
            }

        }

	  }, []);

      const [showCompleteAccount, setShowCompleteAccount] = useState(false);
      const handleCloseCompleteAccount = () => setShowCompleteAccount(false);
      const handleShowCompleteAccount = () => setShowCompleteAccount(true);

      const [showRecoverPassword, setShowRecoverPassword] = useState(false);
      const handleCloseRecoverPassword = () => setShowRecoverPassword(false);
      const handleShowRecoverPassword = () => setShowRecoverPassword(true);
 
      const [resetInfo, setResetInfo] = useState(null);
 
const showWeb3Container = () => {
    const web3Container = document.getElementById('w3a-container');
    web3Container.style.display = "block"; 
}

const hideWeb3Container = () => {
    const web3Container = document.getElementById('w3a-container');
    web3Container.style.display = "none"; 
}

const getAccounts = async () => {
    let curProvider = provider
    if (true || !curProvider) {
        const web3authProvider = await web3auth.connect();
        setProvider(web3authProvider);
        curProvider = web3authProvider;            
    }
    /*
    const rpc = new RPC(curProvider);
    const address = await rpc.getAccounts();
    return address;
    */

    const address = await getPublicWallet(curProvider);
    return address;


};

const doAirdrop = (pubkey) => {
 
    const config = getConnectionConfig();
    // Initialize signMsgFn by drawing out the private key first 
   
   if(config.cluster === "devnet") {
        const AIRDROP_AMOUNT = 2 * LAMPORTS_PER_SOL; // 1 SOL 

        pubkey && connection.requestAirdrop(pubkey, AIRDROP_AMOUNT)
        .then((id: string) => { 
            //console.log(`Transaction ID ${id}`);
            //setTxid(id);
            connection.confirmTransaction(id)
            .then((confirmation: RpcResponseAndContext<SignatureResult>) => {
                
                // toast.success("Airdropped 2 SOLS to your wallet", {
                //     className : "success-toast"
                // });
            });
        })
        .catch(console.error);
    }
}

const loginWeb3Auth = async (callback = null) => {
    if (!web3auth) {
      console.log("web3auth not initialized yet");
    
      return;
    }
    claimCallback = callback;

    showWeb3Container();

    const web3authProvider = await web3auth.connect();
    setProvider(web3authProvider);    

    if (web3auth.provider) {
        setProvider(web3auth.provider);
    }
    hideWeb3Container();
    
    loginAccountHandler();
};

const loginAccountHandler = async () => {
    const userInfo = await web3auth.getUserInfo();
    //console.log(userInfo);
    const params = {
        email: userInfo.email,
        name: userInfo.name,
        typeOfLogin: userInfo.typeOfLogin,
    } 
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(params)
    };
    fetch(backend_api_url + 'api/v1/users/login-v2', requestOptions)
        .then(response => response.json())
        .then(data => {
            if(data.status === 0) {
                //setErrorMessageLogin(data.message);
                showToastError(data.message);
            }else if(data.status === 1) {
                //show complete account
                if(data.isNew === 1 ) {
                    //doAirdrop(new PublicKey(data.wallet)); 
                    handleShowCompleteAccount();

                }
                
                const userInfo = {
                    token: data.token,
                    secret: data.secret,
                    username: data.username,
                    wallet: data.wallet,
                    isNew: data.isNew,
                    isPromptEmail: data.isPromptEmail,
                    isam: data.is_admin
                }

                setIsAdmin(data.is_admin === 1 ? true : false);
                
                setHasUserInfo(true);
                
                setCurrentUsername(data.username);
                
                setCurrentEmail('');
                if(data.isPromptEmail == 1) {
                    setPromptMail(true);
                } else {
                    setPromptMail(false);
                }

                localStorage.setItem(process.env.REACT_APP_LOCAL_STORAGE, JSON.stringify(userInfo));
                if(data.wallet === 'reload') {
                    reloadWallet();
                }else{
                    loadSolBalance();
                }

                if(data.isNew != 1 ) {
                    
                    checkPendingData(claimCallback);
                }
            }
        });
}

let retryWallet = 0;
    const reloadWallet = async() => {
        const address = await getAccounts();
        //console.log(address);
         
        if(!address) {
            retryWallet++;
            if(retryWallet < 10) {
                setTimeout(reloadWallet, 2000);
            }
            return;
        }
        const userData = getUserSessionData(); 

        const params = {
            token: userData.token,
            secret: userData.secret,
            wallet: address
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/users/update-wallet', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    const userInfo = {
                        token: data.token,
                        secret: data.secret,
                        username: data.username,
                        wallet: data.wallet,
                        isNew: userData.isNew
                    }

                    //doAirdrop(new PublicKey(data.wallet));

                    setHasUserInfo(true);
                    loadSolBalance();

                    localStorage.setItem(process.env.REACT_APP_LOCAL_STORAGE, JSON.stringify(userInfo));
                }
            });

    }

    const loginHandler = () => {
        loginWeb3Auth();
   } 
 
   const logoutHandler = async () => {
    localStorage.removeItem(process.env.REACT_APP_LOCAL_STORAGE);
    setHasUserInfo(false);
    await web3auth.logout();
    //setProvider(null);
}

const showToastError = (message) => {
    toast.dismiss();
    setTimeout(function(){
        toast.error((<Fragment>{message}<div className="toast-close-section"><button className="link-button toast-close">close message</button></div></Fragment>),{
            autoClose: false,
            //closeButton: false
          });
    }, 500);
    
} 

const updateAccount = () => {
    
    if(usernameInput.current?.value == '') {
        showToastError("Please enter a username");
        return;
    }

    const userData = getUserSessionData();


    const params = {
        token: userData.token,
        secret: userData.secret,
        username: usernameInput.current?.value,
        email: promptMail ? emailInput.current?.value : '',

    }


    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(params)
    };
    fetch(backend_api_url + 'api/v1/users/update', requestOptions)
        .then(response => response.json())
        .then(data => {
            if(data.status === 0) {
                showToastError(data.message);
                //setShowErrorCompleteAccount(true);
                if(data.invalidUser === 1) {
                    localStorage.clear();
                    handleCloseCompleteAccount();
                }
            }else if(data.status === 1) {
                //show complete account
                //setShowErrorCompleteAccount(false);
                setShowCompleteAccount(false);
                
                const userInfo = {
                    token: data.token,
                    secret: data.secret,
                    username: data.username,
                    wallet: data.wallet,
                    isNew: 0,
                    isPromptEmail: 0
                }
                setCurrentUsername(data.username);
                setHasUserInfo(true);
                localStorage.setItem(process.env.REACT_APP_LOCAL_STORAGE, JSON.stringify(userInfo));

                checkPendingData(claimCallback);
            }
        });
}

    return (
        <Fragment>
           
            <Header setAvatar={setAvatar} loginWeb3Auth={loginWeb3Auth} loginAccountHandler={loginAccountHandler} isAdmin={isAdmin} setIsAdmin={setIsAdmin} hasUserInfo={hasUserInfo} setHasUserInfo={setHasUserInfo} showWeb3Container={showWeb3Container} hideWeb3Container={hideWeb3Container} web3auth={web3auth} provider={provider} setWeb3auth={setWeb3auth} setProvider={setProvider} resetInfo={resetInfo} showRecoverPassword={showRecoverPassword} handleCloseRecoverPassword={handleCloseRecoverPassword} showCompleteAccount={showCompleteAccount} handleCloseCompleteAccount={handleCloseCompleteAccount} solBalance={solBalance} loadSolBalance={loadSolBalance} unreadCount={unreadCount} loadUnreadHandler={loadMessageUnread}></Header>
 
      
            <div className="">
                <BrowserRouter >
                    <Routes>
                        <Route path="/" element={<MainContainer provider={provider}  setClassInfo={props.setClassInfo} />} />

                        <Route path="/admin" element={<UserContainer setClassInfo={props.setClassInfo}/>} />
                        <Route path="/admin/users" element={<UserContainer setClassInfo={props.setClassInfo}/>} />
                        <Route path="/admin/creators" element={<CreatorContainer setClassInfo={props.setClassInfo}/>} />
                        <Route path="/admin/collections" element={<MachineListContainer setClassInfo={props.setClassInfo}/>} />
                        <Route path="/admin/purchases" element={<PurchaseListContainer setClassInfo={props.setClassInfo}/>} />
                        <Route path="/admin/actions" element={<ActionsContainer setClassInfo={props.setClassInfo}/>} />
                        <Route path="/admin/adjust-credits" element={<AdjustCreditsContainer setClassInfo={props.setClassInfo}/>} />

                        <Route path="/login" element={<MainContainer provider={provider}  setClassInfo={props.setClassInfo}/>} />
                        <Route path="/create-collection" element={<CreateCollection type="create-collection" setClassInfo={props.setClassInfo} loginWeb3Auth={loginWeb3Auth} loginAccountHandler={loginAccountHandler} web3auth={web3auth} provider={provider} setWeb3auth={setWeb3auth} setProvider={setProvider} loadSolBalance={loadSolBalance} />} />
                        <Route path="/edit-collection/:id/:userId" element={<CreateCollection type="edit-settings" setClassInfo={props.setClassInfo} loginWeb3Auth={loginWeb3Auth} loginAccountHandler={loginAccountHandler} web3auth={web3auth} provider={provider} setWeb3auth={setWeb3auth} setProvider={setProvider} loadSolBalance={loadSolBalance} />} />
                        
                        <Route path="/collection-wallet/:address" element={<CollectionWallet type="view-settings" setClassInfo={props.setClassInfo} loginWeb3Auth={loginWeb3Auth} loginAccountHandler={loginAccountHandler} web3auth={web3auth} provider={provider} setWeb3auth={setWeb3auth} setProvider={setProvider} loadSolBalance={loadSolBalance} />} />

                        <Route path="/collection/:id/settings" element={<CreateCollection type="view-settings" setClassInfo={props.setClassInfo} loginWeb3Auth={loginWeb3Auth} loginAccountHandler={loginAccountHandler} web3auth={web3auth} provider={provider} setWeb3auth={setWeb3auth} setProvider={setProvider} loadSolBalance={loadSolBalance} />} />

                        <Route path="/add" element={<ListingForm setClassInfo={props.setClassInfo} loginWeb3Auth={loginWeb3Auth} loginAccountHandler={loginAccountHandler} web3auth={web3auth} provider={provider} setWeb3auth={setWeb3auth} setProvider={setProvider} loadSolBalance={loadSolBalance} />} />
                        <Route path="/my-gacha" element={<MyGacha showToastError={showToastError} setClassInfo={props.setClassInfo} loginWeb3Auth={loginWeb3Auth} loginAccountHandler={loginAccountHandler} web3auth={web3auth} provider={provider} setWeb3auth={setWeb3auth} setProvider={setProvider} loadSolBalance={loadSolBalance} />} />
                        <Route path="/collections" element={<MyGacha showToastError={showToastError} setClassInfo={props.setClassInfo} loginWeb3Auth={loginWeb3Auth} loginAccountHandler={loginAccountHandler} web3auth={web3auth} provider={provider} setWeb3auth={setWeb3auth} setProvider={setProvider} loadSolBalance={loadSolBalance} />} />
                        <Route path="/collection/:address/claim" element={<ViewGacha claim="1" hasUserInfo={hasUserInfo}  setClassInfo={props.setClassInfo} loginWeb3Auth={loginWeb3Auth} loginAccountHandler={loginAccountHandler} web3auth={web3auth} provider={provider} setWeb3auth={setWeb3auth} setProvider={setProvider} loadSolBalance={loadSolBalance} />} />
                        <Route path="/collection/:address" element={<ViewGacha claim="0" hasUserInfo={hasUserInfo}  setClassInfo={props.setClassInfo} loginWeb3Auth={loginWeb3Auth} loginAccountHandler={loginAccountHandler} web3auth={web3auth} provider={provider} setWeb3auth={setWeb3auth} setProvider={setProvider} loadSolBalance={loadSolBalance} />} />
                        <Route path="/wallet" element={<WalletInfo setClassInfo={props.setClassInfo} provider={provider} loginWeb3Auth={loginWeb3Auth} solBalance={solBalance} loadSolBalance={loadSolBalance}/>} /> 
                        <Route path="/wallet-activity" element={<WalletActivityNew setClassInfo={props.setClassInfo} provider={provider} loginWeb3Auth={loginWeb3Auth} solBalance={solBalance} loadSolBalance={loadSolBalance}/>} /> 
                        <Route path="/wallet-activity-old" element={<WalletActivity setClassInfo={props.setClassInfo} provider={provider} loginWeb3Auth={loginWeb3Auth} solBalance={solBalance} loadSolBalance={loadSolBalance}/>} /> 
                        <Route path="/purchases" element={<Purchases setClassInfo={props.setClassInfo} provider={provider} loginWeb3Auth={loginWeb3Auth} solBalance={solBalance} loadSolBalance={loadSolBalance}/>} /> 

                        <Route path="/account" element={<MyAccount showToastError={showToastError} setClassInfo={props.setClassInfo} loginWeb3Auth={loginWeb3Auth} loginAccountHandler={loginAccountHandler} web3auth={web3auth} provider={provider} setWeb3auth={setWeb3auth} setProvider={setProvider} loadSolBalance={loadSolBalance} />} />
                        <Route path="/user/:userName" element={<UserView setClassInfo={props.setClassInfo} />} />
                        <Route path="/search/:searchTitle" element={<SearchView setClassInfo={props.setClassInfo} />} />

                        <Route path="/support" element={<Support setClassInfo={props.setClassInfo} />} />
                        <Route path="/privacy" element={<Privacy setClassInfo={props.setClassInfo} />} />
                        <Route path="/terms" element={<Terms setClassInfo={props.setClassInfo} />} />
                        <Route path="/how-it-works" element={<HowItWorks setClassInfo={props.setClassInfo} />} />
                        <Route path="/for-creators" element={<ForCreators setClassInfo={props.setClassInfo} />} />

                        <Route path="/activate/:id/:email" element={<MainContainer provider={provider}  setClassInfo={props.setClassInfo} />} />
                        <Route path="/test-paypal" element={<TestPaypal setClassInfo={props.setClassInfo} />} />
                        {/* <Route path="/sample-modal" element={<SampleModal setClassInfo={props.setClassInfo} />} />
                        <Route path="/test-stripe" element={<TestStripe setClassInfo={props.setClassInfo} />} />
                        <Route path="/test-stripe-payment" element={<TestStripePayment setClassInfo={props.setClassInfo} />} /> */}

                        {/* <Route path="/listings" element={<ListingResults setClassInfo={props.setClassInfo} />} />

                        <Route path="/edit/:txnId" element={<ListingForm setClassInfo={props.setClassInfo} loginWeb3Auth={loginWeb3Auth} loginAccountHandler={loginAccountHandler} web3auth={web3auth} provider={provider} setWeb3auth={setWeb3auth} setProvider={setProvider} loadSolBalance={loadSolBalance} />} />

                        <Route path="/search" element={<Search setClassInfo={props.setClassInfo} />} />

 
                        <Route path="/user/update" element={<EditAccount setClassInfo={props.setClassInfo} />} />
                        <Route path="/user/:userName" element={<UserView setClassInfo={props.setClassInfo} />} />
                        <Route path="/message/:userName/:txnId" element={<MessageUser setClassInfo={props.setClassInfo} loadHandler={loadMessageUnread} />} />
                        <Route path="/message/:userName" element={<MessageUser setClassInfo={props.setClassInfo} loadHandler={loadMessageUnread} />} />

                        <Route path="/messages" element={<Messages setClassInfo={props.setClassInfo} />} />
                        <Route path="/account" element={<MyAccount setClassInfo={props.setClassInfo} />} /> 
                        */}

                    </Routes>
                </BrowserRouter >
            </div>
 
            <div id="Footer">
                <div id="FooterDiv">
                    <div className="logoDiv">
                        <a className="logo" href="/"><img src="/images/logo.png" alt="ToyBot" title="ToyBot" width="120"/></a> 
                    </div>

                    <div id="Copyright"> 
                        <span>&copy;2023</span> - <a href="/support">Contact Us</a> - <a href="/privacy">Privacy</a> - <a href="/terms">Terms</a>
                    </div>

                    <div id="Copyright" style={{"marginTop":"10px"}}> 
                         <a href="#">twitter</a> // <a href="#">discord</a> // <a href="#">API</a>
                    </div>
                </div>
            </div>  
            
            <ToastContainer position="top-center" />

            <Modal className="modal-registration complete-username" show={showCompleteAccount} onHide={handleCloseCompleteAccount}  backdrop="static">
                <Modal.Header closeButton>
                <Modal.Title>Complete Account</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label className='control-label'>Username</label>
                        <input type="text" className="form-control" defaultValue={currentUsername} ref={usernameInput}/>
                    </div>
                    {
                        promptMail && 
                        <div className="form-group">
                            <label className='control-label'>Email</label>
                            <input type="email" className="form-control" defaultValue={currentEmail} ref={emailInput}/>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                <button className="btn btn-danger blue" onClick={updateAccount}>
                    Update
                </button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}

export default Gacha;